import React, { Ref } from "react";

import { StrainRelatives } from "custom-types/StrainV2";

import StrainLineageLink from "./StrainLineageLink";

type Props = {
  child1Ref: Ref<HTMLDivElement>;
  child2Ref: Ref<HTMLDivElement>;
  children: StrainRelatives;
  parentalClassExtension: string;
};

const StrainLineageTwoChildren = ({
  child1Ref,
  child2Ref,
  children,
  parentalClassExtension,
}: Props) => (
  <>
    <div
      className={`lineage__left-child--${parentalClassExtension}`}
      ref={child1Ref}
    >
      <StrainLineageLink
        strain={children[0]}
        altText="First strain child"
        parentOrChild="child"
      />
    </div>
    <div
      className={`lineage__right-child--${parentalClassExtension}`}
      ref={child2Ref}
    >
      <StrainLineageLink
        strain={children[1]}
        altText="Second strain child"
        parentOrChild="child"
      />
    </div>
    <style jsx>{`
      .lineage__left-child--two-parents {
        grid-column: 2 / 3;
        grid-row: 6 / 8;
        width: 80px;
      }
      .lineage__right-child--two-parents {
        grid-column: 5 / 6;
        grid-row: 6 / 8;
        width: 80px;
      }
      .lineage__left-child--single-parent {
        grid-column: 1 / 3;
        grid-row: 7 / 9;
        width: 80px;
      }
      .lineage__right-child--single-parent {
        grid-column: 5 / 7;
        grid-row: 7 / 9;
        width: 80px;
      }
      .lineage__left-child--no-parents {
        grid-column: 1 / 3;
        grid-row: 4 / 6;
        width: 80px;
      }
      .lineage__right-child--no-parents {
        grid-column: 5 / 7;
        grid-row: 4 / 6;
        width: 80px;
      }
    `}</style>
  </>
);

export default StrainLineageTwoChildren;
